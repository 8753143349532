import React, { useState, useEffect, useMemo } from "react";
import { useConfig } from "@peracto/peracto-config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxFull } from "@fortawesome/pro-regular-svg-icons/faBoxFull";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch, connectHits, Configure } from "react-instantsearch-dom";
import isObjectLike from "lodash/isObjectLike";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { formatPrice } from "../utils";

import styled from "@emotion/styled";

function extractAndTruncate(htmlString, maxLength = 100) {
    // Create a temporary DOM element to parse the HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;

    // Extract the text content from the HTML
    const textContent = tempDiv.textContent || tempDiv.innerText || "";

    // Truncate the text without cutting words in half
    if (textContent.length > maxLength) {
        let truncated = textContent.substring(0, maxLength);
        const lastSpaceIndex = truncated.lastIndexOf(" ");
        if (lastSpaceIndex > 0) {
            truncated = truncated.substring(0, lastSpaceIndex);
        }
        return truncated + "...";
    }

    return textContent;
}

const ScrollbarStyles = styled.div`
    ::-webkit-scrollbar {
        height: 20px;
    }

    /* 4px scroll thumb. 10px margin from right margin */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 15px 15px #d6d8d9;
        border-top: solid 15px transparent;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 15px 15px #333;
        border-top: solid 15px transparent;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }
`;

// Attributes that don't start with 'attributes.*'
const UNPREFIXED_ATTRIBUTES = ["sku"];

let Icon = () => (
    <div className="flex items-center justify-center w-full py-10">
        <FontAwesomeIcon icon={faBoxFull} size="6x" />
    </div>
);

const ProductCard = ({
    product,
    isEditMode,
    attributes,
    attributeOperator,
    border,
    index,
    ...restProps
}) => {
    const images = product.resources?.filter((r) => r.type === "image");
    const firstImage = images ? sortBy(images, ["displayOrder"])[0] : null;
    const price =
        product?.fromPrices?.price?.inc ||
        product?.prices?.cheapestPrice?.inc ||
        product?.prices?.price?.inc ||
        0;

    const description = isObjectLike(product.attributes)
        ? product.attributes.description
        : "";

    return (
        <div
            className={`flex flex-col mb-0 bg-brand-white product-card min-w-[280px] max-w-[280px] p-[30px] border ${
                border ? "border-brand-grey" : "border-white"
            } items-start`}
            data-testid={`product-card`}
            {...restProps}
        >
            <a
                href={`/products/${product.slug}`}
                onClick={(e) => {
                    e.preventDefault();
                    if (isEditMode) {
                        return null;
                    } else {
                        window.location = `/products/${product.slug}`;
                    }
                }}
            >
                <img
                    alt={
                        isObjectLike(product.attributes)
                            ? product.attributes.product_name
                            : ""
                    }
                    src={firstImage?.location || "/images/placeholder.png"}
                    className={`product-card__media w-full flex-[1] mb-[30px] aspect-square`}
                    data-testid={`product-card__media`}
                />
            </a>

            <div className="mb-[15px] flex flex-col gap-[15px]">
                <a
                    className="product-card__name font-bold !text-brand-black !no-underline text-[14px] lg:text-[16px] leading-[20px] flex-grow"
                    data-testid={`product-card__name`}
                    href={`/products/${product.slug}`}
                    onClick={(e) => {
                        e.preventDefault();
                        if (isEditMode) {
                            return null;
                        } else {
                            window.location = `/products/${product.slug}`;
                        }
                    }}
                >
                    {isObjectLike(product.attributes) &&
                        product.attributes.product_name}
                </a>

                {typeof price !== "undefined" && (
                    <div className="flex items-center gap-x-[5px]">
                        <span className="flex items-baseline gap-x-[5px]">
                            <span className="text-[12px] leading-[12px]">
                                From
                            </span>
                            <span
                                className="text-[20px] leading-[20px] font-[600]"
                                data-testid="product-card__now-price"
                            >
                                {formatPrice(price, "GBP")}
                            </span>
                        </span>
                        <div
                            data-testid="tax-message"
                            className="flex flex-col text-[10px] font-normal leading-[10px] text-brand-black"
                        >
                            <span>inc.</span>
                            <span>VAT</span>
                        </div>
                    </div>
                )}
            </div>

            {description && (
                <p className="mb-[15px] text-[14px] leading-[20px] text-brand-darker-grey flex-grow">
                    {extractAndTruncate(description)}
                </p>
            )}

            <a
                className="flex h-[45px] justify-center items-center py-[10px] px-[30px] bg-brand-tertiary !text-brand-white !no-underline font-bold leading-[18px] text-center whitespace-nowrap mt-[15px]"
                href={`/products/${product.slug}`}
                onClick={(e) => {
                    e.preventDefault();
                    if (isEditMode) {
                        return null;
                    } else {
                        window.location = `/products/${product.slug}`;
                    }
                }}
            >
                Choose options & buy
            </a>
        </div>
    );
};

const Hits = (props) => {
    const { hits, isEditMode, fallbackImage, border } = props;

    console.log("klfksdf", props);

    const inStorefront = !window?.location?.pathname.includes("/content/edit/");

    return hits.length > 0 ? (
        <>
            {hits.map((hit, index) => (
                <ProductCard
                    key={hit?.slug || `hit_${index}`}
                    product={hit}
                    isEditMode={isEditMode}
                    fallbackImage={fallbackImage}
                    border={border}
                    index={index}
                />
            ))}
        </>
    ) : isEditMode || !inStorefront ? (
        <Icon />
    ) : null;
};
const CustomHits = connectHits(Hits);

const ProductsContent = ({
    numberOfProducts = 4,
    attributes = [],
    attributeOperator = "and",
    border,
    isEditMode,
}) => {
    const config = useConfig();
    const algolia = config.get("algolia") || {};

    const { applicationId, searchApiKey, indexName } = algolia;

    const searchClient = algoliasearch(applicationId, searchApiKey);

    const [facetFilters, setFacetFilters] = useState();

    useEffect(() => {
        if (attributes?.length > 0) {
            const categories = attributes?.reduce(
                (acc, { attribute, value }) => {
                    const attributeKey = UNPREFIXED_ATTRIBUTES.includes(
                        attribute
                    )
                        ? attribute
                        : `attributes.${attribute}`;
                    if (value.length > 0) acc.push(`${attributeKey}:${value}`);

                    return acc;
                },
                []
            );

            setFacetFilters(categories);
        }
    }, [attributes]);

    if (isEmpty(algolia)) {
        console.error(
            "No Algolia configuration defined. Please refer to the Peracto documentation."
        );
    }

    return useMemo(
        () =>
            isEmpty(algolia) ? null : (
                <InstantSearch
                    searchClient={searchClient}
                    indexName={indexName}
                >
                    <Configure
                        hitsPerPage={numberOfProducts}
                        facetFilters={
                            facetFilters
                                ? attributeOperator === "and"
                                    ? facetFilters
                                    : [facetFilters]
                                : null
                        }
                    />

                    <CustomHits isEditMode={isEditMode} border={border} />
                </InstantSearch>
            ),
        [
            algolia,
            searchClient,
            indexName,
            numberOfProducts,
            border,
            facetFilters,
            attributeOperator,
            isEditMode,
        ]
    );
};

const ProductsRenderer = ({ state, ...props }) => {
    return (
        <ScrollbarStyles className="flex flex-row gap-[30px] flex-nowrap overflow-auto product-card-container content__products">
            <ProductsContent {...state} {...props} />
        </ScrollbarStyles>
    );
};

export default ProductsRenderer;
