import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentLines } from "@fortawesome/pro-regular-svg-icons/faCommentLines";
import styled from "@emotion/styled";

const ScrollbarStyles = styled.div`
    ::-webkit-scrollbar {
        height: 20px;
    }

    /* 4px scroll thumb. 10px margin from right margin */
    ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 15px 15px #d6d8d9;
        border-top: solid 15px transparent;
    }

    ::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 15px 15px #333;
        border-top: solid 15px transparent;
    }

    ::-webkit-scrollbar-button {
        display: none;
    }
`;

const Icon = () => (
    <div className="flex py-10 justify-center items-center w-full">
        <FontAwesomeIcon icon={faCommentLines} size="6x" />
    </div>
);

const TestimonialText = ({ text }) => {
    const [showFullText, setShowFullText] = useState(false);

    const truncatedText =
        text?.length > 250 ? `${text.substr(0, 250)}...` : text;

    return (
        <div className="flex flex-col items-start gap-[30px] lg:gap-[45px] h-full z-[20] mt-[10px] flex-grow">
            <div className="flex flex-col items-start ">
                <p className="mb-0 !text-[16px] !leading-[24px] text-black">
                    {showFullText ? text : truncatedText}
                </p>

                {text?.length > 250 && (
                    <button
                        className="text-brand-secondary underline"
                        onClick={() => {
                            setShowFullText(!showFullText);
                        }}
                    >
                        Read {showFullText ? "less" : "more"}
                    </button>
                )}
            </div>
        </div>
    );
};

const TestimonialsComponent = ({
    state: { testimonials = [], border, selectedCardIndex = null },
    isEditMode,
    onChange,
    focused,
}) => {
    return (
        <>
            {testimonials?.length > 0 ? (
                <ScrollbarStyles className="flex flex-row content__testimonials overflow-auto gap-[30px]">
                    {testimonials.map((testimonial, idx) => {
                        return (
                            <div
                                key={`testimonials_${idx}`}
                                style={{
                                    boxShadow:
                                        selectedCardIndex === idx &&
                                        focused &&
                                        "inset 0 0 0 3px red",
                                }}
                                className={`flex flex-col flex-[340px] min-w-[340px] max-w-[340px] gap-y-[15px]`}
                                onClick={(e) => {
                                    if (isEditMode) {
                                        e.preventDefault();
                                        onChange({ selectedCardIndex: idx });
                                    }
                                }}
                            >
                                <div
                                    className={`flex min-w-full flex-col flex-grow items-start justify-start mb-0 bg-brand-white p-[15px] md:p-[30px] border relative ${
                                        border
                                            ? "border-brand-grey"
                                            : "!border-transparent"
                                    } overflow-hidden items-start`}
                                >
                                    <svg
                                        width="35"
                                        height="26"
                                        viewBox="0 0 35 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="w-[35px] h-[40px] min-h-[40px]"
                                    >
                                        <path
                                            d="M7.5 0.835938C11.6406 0.835938 15 4.19531 15 8.33594V15.8359C15 21.3828 10.4688 25.8359 5 25.8359C3.59375 25.8359 2.5 24.7422 2.5 23.3359C2.5 22.0078 3.59375 20.8359 5 20.8359C7.73438 20.8359 10 18.6484 10 15.8359V15.4453C9.14062 15.6797 8.35938 15.8359 7.5 15.8359C3.28125 15.8359 0 12.5547 0 8.33594C0 4.19531 3.28125 0.835938 7.5 0.835938ZM35 8.33594V15.8359C35 21.3828 30.4688 25.8359 25 25.8359C23.5938 25.8359 22.5 24.7422 22.5 23.3359C22.5 22.0078 23.5938 20.8359 25 20.8359C27.7344 20.8359 30 18.6484 30 15.8359V15.4453C29.1406 15.6797 28.3594 15.8359 27.5 15.8359C23.2812 15.8359 20 12.5547 20 8.33594C20 4.19531 23.2812 0.835938 27.5 0.835938C31.6406 0.835938 35 4.19531 35 8.33594Z"
                                            fill="#0079C2"
                                        />
                                    </svg>

                                    <TestimonialText
                                        text={testimonial.content}
                                    />
                                </div>

                                <div className="flex flex-row flex-nowrap items-center gap-x-[20px]">
                                    <div className="flex items-center justify-center overflow-hidden w-[50px] h-[50px] object-cover rounded-full bg-brand-grey">
                                        <img
                                            src={
                                                testimonial.image ||
                                                "https://placehold.co/200x200/EEE/31343C"
                                            }
                                            className="min-h-full min-w-full flex-shrink-0"
                                            alt="hello"
                                        />
                                    </div>

                                    <div className="flex flex-col items-start gap-y-[2px]">
                                        <p className="!text-[14px] !leading-[18px] text-brand-black font-bold">
                                            {testimonial.customerName || ""}
                                        </p>
                                        <p className="!text-[14px] !leading-[18px] text-brand-black font-normal">
                                            {testimonial.customerJobTitle || ""}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </ScrollbarStyles>
            ) : (
                <Icon />
            )}
        </>
    );
};

export default TestimonialsComponent;
