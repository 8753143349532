const { darken, lighten } = require("polished");

// Used so hover colors get automatically updated
const brandPrimaryColor = "#4A986A";
const brandSecondaryColor = "#0079C2";
const brandTertiaryColor = "#ee3224";

const brandBlackColor = "#333333";
const brandGreyColor = "#D6D8D9";
const brandWhiteColor = "#FFFFFF";

const colors = {
    // Brand & Accent
    "brand-primary": brandPrimaryColor,
    "brand-secondary": brandSecondaryColor,
    "brand-tertiary": brandTertiaryColor,

    // Base
    "brand-black": brandBlackColor,
    "brand-darker-grey": "#555555",
    "brand-dark-grey": "#999999",
    "brand-grey": brandGreyColor,
    "brand-light-grey": "#FAFAFA",
    "brand-white": brandWhiteColor,

    // Messages
    "brand-success": "#2DB200",
    "brand-warning": "#F49C10",
    "brand-danger": "#EE3224",
    "brand-info": "#0079C2",

    // Alerts
    "brand-success-alert": "#E9F7E5",
    "brand-warning-alert": "#FEF5E6",
    "brand-danger-alert": "#FCE5E7",
    "brand-info-alert": "#E5F4FC",

    // Alert Borders
    "brand-success-alert-border": "#D5F0CE",
    "brand-warning-alert-border": "#FDECD0",
    "brand-danger-alert-border": "#F9CED2",
    "brand-info-alert-border": "#CEEAF9",

    // Checkbox Count
    "brand-checkbox-count": "#909090",

    // Lighten & Darken
    "brand-primary-lighten": lighten(0.05, brandPrimaryColor),
    "brand-primary-darken": darken(0.05, brandPrimaryColor),

    "brand-secondary-lighten": lighten(0.05, brandSecondaryColor),
    "brand-secondary-darken": darken(0.05, brandSecondaryColor),

    "brand-tertiary-lighten": lighten(0.05, brandTertiaryColor),
    "brand-tertiary-darken": darken(0.05, brandTertiaryColor),

    "brand-black-lighten": lighten(0.05, brandBlackColor),
    "brand-black-darken": darken(0.05, brandBlackColor),

    "brand-grey-lighten": lighten(0.05, brandGreyColor),
    "brand-grey-darken": darken(0.05, brandGreyColor),

    "brand-white-lighten": lighten(0.05, brandWhiteColor),
    "brand-white-darken": darken(0.05, brandWhiteColor),
};

const fonts = {
    heading: ["FrutigerRoman, sans-serif"],
    body: ["FrutigerLight, sans-serif"],
};

module.exports = { colors, fonts };
